<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for=""> Fournisseur </label>
        <select class="form-select" v-model="check.supplier_reference">
          <option value="" default></option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            <div class="row justify-content-between">
              <span class="col-auto">
                {{ supplier.society }}
              </span>
              <span class="col-auto">
                {{ supplier.society }}
              </span>
            </div>
          </option>
        </select>
      </div>

      <div class="col-2">
        <label for="">Montant</label>
        <input type="text" v-model="check.amount" class="form-control" />
      </div>

      <div class="col">
        <label for=""> Date </label>
        <input type="date" v-model="check.date" class="form-control" />
      </div>

      <div class="col-2">
        <label for="">Mode de paiement</label>
        <input :value="'Chèque'" :disabled="true" class="form-select" />
      </div>

      <div class="col">
        <label for="">Numéro de chèque</label>
        <input type="text" v-model="check.checkNumber" class="form-control" />
      </div>
    </div>
    <br />

    <div class="row">
      <div class="col-12">
        <label for="">Remarques</label>
        <textarea class="form-control" cols="30" rows="3"></textarea>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <button
          @click="onFileChange"
          class="btn border border-3 rounded border-secondary w-100 d-flex justify-content-center align-items-center"
          :style="{
            borderStyle: 'dashed !important',
          }"
        >
          <img v-show="imageUrl" :src="imageUrl" alt="" id="imagePreview" />
          <div v-show="!imageUrl" id="imagePreview">
            <p>
              Importer ici l'image ou le scan de votre chèque, le chèque sera
              crypté avant d'être stocké dans la base de données. Cela permet de
              protéger les informations sensibles du chèque.
            </p>
          </div>
        </button>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(check)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      check: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      file: null,
      imageUrl: "",
    };
  },
  computed: {
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("check/storeSupplierCheck", data);
    },
    onFileChange(event) {
      // this.file = event.target.files[0];
      const input = document.createElement("input");
      input.type = "file";
      input.style.display = "none";

      input.addEventListener("change", (event) => {
        this.file = event.target.files[0];

        // Show the image
        this.imageUrl = URL.createObjectURL(this.file);
      });

      document.body.appendChild(input);
      input.click();
      document.body.removeChild(input);
      // Show the image
      this.imageUrl = URL.createObjectURL(this.file);
    },
  },
  beforeMount() {
    this.$store.dispatch("supplier/getAll");
  },
};
</script>
<style scoped>
#imagePreview {
  width: 800px !important;
  height: 352px !important;
  left: auto;
  right: auto;
  border: none !important;
}
</style>
